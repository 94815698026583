.checkout {
  .product__thumb {
    margin-right: 0;
  }
  .checkout__content {
    .cart-header__title--products {
      margin-right: 0;
    }
  }
  .checkout__new-account {
    margin-right: 0;
  }
  #checkout_complete {
    .picker-radio {
      float: right;
    }
  }
  .cart-item {
    select {
      background-position: 5% 64%;
    }
    .cart-item__product-name {
      @include swap_direction(padding, 0 18px 0 0);
    }
  }
  #order-summary-panel {
    .label {
      margin-right: 0;
    }
    .order-summary__subtotal-label,
    .order-summary__shipping-label,
    .order-summary__tax-label {
      padding-#{$ldirection}: 0px;
    }
  }
}

.recommended-products-panel {
  .checkout__panel-content {
    .recommended-product-items {
      .recommended-item {
        margin-right: 0;
      }
    }
  }
}

.adpl_enabled {
  .adpl {
    input[type='text'] + label,
    input[type='email'] + label,
    input[type='password'] + label {
      font-size: 16px;
    }
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      font-size: 16px;
    }
  }
}

body {
  .checkout__index-content {
    font-size: 16px;
  }
  .site-header {
    .site-header__extra {
      .mobile-checkout-back {
        text-align: $ldirection;
        @include swap_direction(margin, 18px 0 0 10px);
      }
    }
    .mobile-checkout-back {
      .icon--arrow--left {
        transform: rotate(180deg);
      }
    }
  }
}

.click-and-collect {
  .picker {
    display: none;
  }
  .form-item {
    span {
      display: none;
    }
  }
}
