.product-foundation-vto {
  &__info-container {
    width: 58%;
    padding-bottom: 15px;
  }
  &__all-shades {
    padding-top: 41px;
    font-weight: bold;
  }
  &__overlay-button {
    background-position: 25px;
    text-align: $ldirection;
  }
  &__btn {
    width: 205px;
    text-align: $ldirection;
  }
  &__mobile-ctrl {
    .container {
      .product-name {
        width: 218px;
      }
    }
  }
}

.custom-kit-formatter {
  & &__overlay {
    &.active {
      #{$rdirection}: 50%;
      #{$ldirection}: auto;
    }
  }
  & &__content-wrapper {
    top: 33%;
    #{$ldirection}: 19%;
  }
}

.LPMcontainer {
  #{$ldirection}: auto !important;
  bottom: 20% !important;
  #{$rdirection}: 2% !important;
}

.virtual-events-carousel-formatter {
  .basic-background-media {
    &__content {
      &.center-aligned {
        left: 50%;
        right: auto;
      }
    }
  }
}
