.enhanced-delivery-page {
  &.checkout {
    &.active-panel-shipping {
      .saved-billing-address {
        #billing-panel-edit {
          #{$rdirection}: 16px;
        }
      }
      .edit-shipping-address {
        .city_container .field[type='text'] {
          & + label {
            display: block;
          }
        }
      }
    }
    .promotions-container {
      .info-link {
        float: $ldirection;
        margin: 5px 0 0;
        @include breakpoint($width-largest) {
          margin-top: 0;
        }
      }
      .promotions-container {
        .email_promotions {
          .pc_email_promo_container .picker-checkbox,
          p:first-child {
            margin: 0;
          }
        }
      }
    }
    .gift-options {
      .picker {
        &.picker-checkbox {
          &.checked {
            .picker-flag {
              &::before {
                @include transform(translateX(-18px));
              }
            }
          }
        }
      }
    }
    &.active-panel-review {
      #review-panel.panel {
        #checkout_complete {
          .picker.picker-checkbox {
            padding: 18px 16px;
            .picker-handle {
              .picker-flag {
                &::before {
                  bottom: 4px;
                  @include breakpoint($width-largest) {
                    bottom: 1px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .checkout {
    .checkout__sidebar {
      .order-summary-panel {
        .giftwrap.label {
          padding-#{$ldirection}: 0;
        }
      }
    }
    .form-item {
      &.textbox {
        padding: 0 17px;
        @include breakpoint($width-largest) {
          padding: 0;
        }
      }
    }
    #offer-code-panel {
      .adpl {
        .valid_marker {
          top: 8px;
          @include breakpoint($width-largest) {
            top: 15px;
          }
        }
        label {
          font-size: 12px;
          text-align: $ldirection;
          &::before {
            margin-#{$ldirection}: 5px;
          }
        }
      }
    }
    #offer_code {
      .form--offer_code_text[type='text'] {
        &.touched + label,
        &:focus + label {
          &::before {
            background: $color--white;
            margin-#{$ldirection}: 5px;
            transform: translateY(-23px) translateY(-0.5px) scale(0.8, 0.8);
          }
        }
      }
    }
    #shipping-panel {
      &.panel {
        .edit-shipping-address {
          .delivery_instructions,
          .delivery_instructions_container {
            height: 94px;
            .countdown-container {
              display: none;
            }
          }
        }
        .new-address-container {
          .bill-to-shipping {
            .picker-checkbox {
              .picker-handle {
                .picker-flag {
                  &::before {
                    bottom: 5px;
                    @include breakpoint($width-largest) {
                      bottom: 1px;
                    }
                  }
                }
              }
            }
          }
        }
        .field-container--grid {
          .form-item {
            .invalid_marker {
              #{$ldirection}: auto;
              #{$rdirection}: 5px;
            }
          }
        }
        .billing-address-form-fields {
          .field-container--grid.country {
            @include swap_direction(padding, 16px 20px 14px 15px);
            background: $color--gray--thick;
            border: 1px solid $color--gray--dusty--disable;
            height: 54px;
            margin-bottom: 22px;
            @include breakpoint($width-largest) {
              border-color: $color--gray--lighter;
            }
            ::after {
              display: none;
            }
            .form-item {
              label {
                .label-content {
                  display: block;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .delivery-address-container {
          .picker {
            @include swap_direction(margin, 0 16px 20px 0);
            .change_link {
              #{$rdirection}: 0;
              margin-#{$rdirection}: 0;
            }
          }
        }
      }
      .shipping-panel__title {
        display: block;
        float: $ldirection;
        font-size: 26px;
        padding-#{$ldirection}: 16px;
      }
      .user_email {
        display: none;
      }
    }
    .accordionPanel {
      i {
        float: $rdirection;
        height: 24px;
        margin-top: 0;
        position: relative;
        top: 0;
        width: 24px;
        &::before,
        &::after {
          @include transform(translate(-50%, -50%));
          #{$ldirection}: 50%;
          background: $color--black;
          content: '';
          display: block;
          height: 2px;
          position: absolute;
          top: 50%;
          width: 16px;
        }
        &::before {
          #{$ldirection}: -2px;
        }
        &::after {
          height: 16px;
          width: 2px;
        }
      }
      .active {
        i {
          &::after {
            visibility: hidden;
          }
        }
      }
    }
    .pc_email_terms_popup {
      display: none;
    }
    .email-and-sms-promotions,
    .gift-options {
      .checkout__subtitle {
        .accordion-icon {
          top: 0;
        }
      }
    }
    &__sidebar {
      &.right {
        .back_to_top {
          .sticky-back-to-top {
            @include transform(rotate(-45deg));
            top: 40%;
          }
        }
        #shopping-bag-wrapper-panel {
          border-bottom: 0;
          .panel.shopping-bag-panel {
            .item-count__edit {
              .shopping-bag-edit-icon {
                #{$ldirection}: calc(100% - 18px);
              }
            }
          }
        }
        .shipping-address-content-panel,
        .billing-address {
          &__content {
            a {
              #{$ldirection}: calc(100% - 18px);
              padding: 0;
            }
          }
        }
      }
    }
    #review-panel {
      #payment-type-cc,
      #payment-type-ncc {
        margin-top: 0;
        .picker-radio.picker {
          &.checked {
            .picker-handle {
              .picker-flag {
                border-radius: 50%;
                height: 18px;
                margin: 0;
                width: 18px;
              }
            }
          }
        }
      }
      #payment-type-cc {
        .picker-radio {
          padding-bottom: 16px;
        }
        .cc-logos {
          float: $rdirection;
          margin-top: 5px;
          @include breakpoint($width-largest) {
            margin-top: 0;
          }
          .label-image {
            margin-#{$rdirection}: 6px;
            &:first-child {
              margin-#{$rdirection}: 0;
            }
          }
        }
        @include breakpoint($bp--xsmall-down) {
          .cc-logos {
            margin-top: 3px;
            width: 195px;
            .label-image {
              margin-#{$rdirection}: 3px;
              width: 30px;
              &:first-child {
                margin-#{$rdirection}: 0;
              }
            }
          }
        }
      }
    }
  }
}
