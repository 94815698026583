.field-container {
  select {
    background-position: 5% 54%;
  }
}

.site-container {
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .product--teaser {
          .product__image {
            margin-right: 0;
          }
        }
      }
    }
  }
  .past-purchases {
    .product__footer {
      .notify-status {
        a.notify_me {
          position: relative;
          padding: 0 20px;
          height: 60px;
          line-height: 64px;
          width: 100%;
          font-size: 1.5rem;
          margin: 25px 0 0 0;
        }
      }
      p.product-item__out-of-stock {
        text-align: center;
        height: 0px;
      }
    }
  }
}

#colorbox {
  #address_form_container {
    .address-overlay-shipping {
      .field-container {
        margin-top: 15px;
      }
    }
  }
}

html {
  overflow-x: hidden;
}
