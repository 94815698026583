#toolbar {
  #toolbar-home {
    float: none;
    li {
      a {
        position: static;
      }
    }
  }
  ul#toolbar-user {
    li {
      display: list-item;
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .email_comp {
      .align-r {
        left: 0;
        right: auto;
      }
    }
    .email_input {
      width: 90%;
    }
  }
}

.store-locator {
  .results_panel {
    .results_side {
      .door_row {
        td {
          text-align: right;
        }
      }
      .sub-options {
        .filters {
          padding-right: 45px;
        }
      }
    }
  }
  .form--search--advanced {
    .options {
      .form-item-wrapper {
        .form-item {
          .country,
          .city_intl {
            padding-right: 45px;
          }
        }
        &.submit_container {
          width: 50%;
        }
      }
    }
  }
}

.site-container {
  .contact-us-page {
    .contact-nav {
      @include breakpoint($bp--small-up) {
        li {
          .details {
            min-height: auto;
            border-bottom: none;
          }
          .title {
            border-top: none;
          }
          border-bottom: 1px solid #7f7f7f;
          border-top: 1px solid #7f7f7f;
          min-height: 260px;
          margin-bottom: 1px;
          &:nth-of-type(4n + 2) {
            margin-right: 27%;
          }
          &:nth-of-type(4n + 3) {
            margin-right: 54%;
          }
          &:nth-of-type(2n + 1) {
            margin-right: -10px;
          }
          &:nth-of-type(4n + 3) {
            border-top: none;
            margin-right: 50%;
          }
        }
      }
    }
  }
  .sec-nav__arrow:before {
    transform: rotate(180deg);
  }
  .page--spp__product {
    .product__footer {
      .icon--arrow--right {
        transform: rotate(180deg);
      }
      .expando-block--expanded {
        .icon--arrow--right {
          transform: rotate(90deg);
        }
      }
    }
  }
  .after-product {
    .icon--arrow--right {
      transform: rotate(180deg);
    }
    .expando-block--expanded {
      .icon--arrow--right {
        transform: rotate(90deg);
      }
    }
  }
  #search-wrapper {
    #zero-results {
      .result-larger {
        font-size: 1.3rem;
      }
    }
  }
  .mpp-custom__header {
    .expando-block__title {
      .icon--arrow--right {
        transform: rotate(180deg);
      }
    }
  }
  .custom-grid {
    &__item-inner {
      .carousel {
        &-controls {
          .slick-prev {
            transform: rotate(180deg);
          }
          .slick-next {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .layout--artistry-artist {
    .artist--products {
      .carousel-controls {
        .slick-prev,
        .slick-next {
          transform: rotate(180deg);
        }
      }
    }
  }
  #product-page-livechat-hitarea {
    width: auto;
  }
  .product {
    &__footer {
      .product_content_fav_mobile {
        .product {
          &__price_ppu {
            display: block;
          }
        }
      }
    }
  }
}

.site-footer {
  &--top {
    .footer-menu--mobile {
      ul.menu {
        a:before {
          transform: rotate(180deg);
        }
      }
    }
  }
  &--bottom {
    .field-menu {
      ul.menu {
        text-align: center;
      }
    }
  }
}

.artistry-videos__filter {
  .arrow-bottom-right {
    select {
      background-position: 5% 57%;
    }
  }
}

.search-filter__trigger {
  .search-filter__trigger {
    &_icon--right,
    &_icon--left {
      transform: rotate(180deg);
    }
  }
}

.collection-products__list {
  .carousel-collection {
    .slick-prev,
    .slick-next {
      transform: rotate(180deg);
    }
  }
  .mobile-collection-products-carousel {
    .slick-list {
      .slick-track {
        float: left;
      }
    }
  }
}

.text-block--quote {
  .text-block__body {
    padding: 0;
    text-indent: 0;
  }
}

.site-header {
  .site-header__tools {
    .site-my-mac__link {
      width: 110%;
    }
  }
}

// Hide the shipping tout on the mobile SPPs
// The original implementation only enabled them in the PC css
.product__shipping {
  display: none;
}

#hppopup {
  padding: 10px;
}

.makeup_studio_only.js-store_landing_only {
  .tout-block-landscape img {
    width: auto;
  }
}

.appt-book {
  .ui-select {
    select {
      background-image: url(/sites/maccosmetics/themes/mac_base/images/fallbacks/corner-triangle-reversed.png) !important;
      background-position: 5% 54% !important;
    }
  }
  #booking-step2 {
    .store-info-content {
      margin-bottom: 20px;
    }
  }
  #booking-step3 {
    .store-info-content {
      margin-bottom: 24px;
    }
  }
  .cal-head-content {
    .default-cal-copy,
    .cal-copy {
      background-image: url(/sites/maccosmetics/themes/mac_base/images/fallbacks/corner-triangle-reversed.png) !important;
      background-position: 5% 54% !important;
    }
  }
  .confirmation-container {
    .appt-date-cal {
      background-position: 0 !important;
      width: 24% !important;
      .cals-container.expanded {
        width: auto;
      }
    }
  }
  .my-appointments {
    .appointment-details {
      .date-time {
        .appt-date-cal {
          width: 60%;
        }
      }
    }
  }
}

.submenu--sticky.appointment-nav {
  .submenu__header,
  .submenu__nav {
    .appointment-nav__link.appointment-header-nav__link {
      background: #f1f2f2 url(/media/export/cms/makeup_services/gnav_hd_make_services_il.png) no-repeat center center;
    }
  }
}
