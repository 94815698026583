.site-header {
  &__container {
    .site-navigation {
      &__carousel {
        .slick-track {
          .block-image-hover__excerpt {
            font-family: $AssistantLight;
          }
        }
      }
    }
  }
}

.site-container {
  .artistry-landing-page-formatter {
    .artistry--title-tile {
      p {
        font-family: $AssistantLight;
      }
    }
    .artistry__tweet {
      .twitter-block__info {
        a {
          font-family: $AssistantLight;
        }
        .twitter-block__time {
          font-family: $AssistantLight;
        }
      }
    }
  }
  .culture-landing {
    &__teaser {
      &-category {
        font-family: $AssistantLight;
      }
      p {
        font-family: $AssistantLight;
      }
    }
    .culture-feature__carousel {
      &-description {
        p {
          font-family: $AssistantRegular;
        }
      }
    }
  }
  .culture-detail {
    &__article-content {
      &__body-snippet {
        p {
          font-family: $AssistantRegular;
        }
      }
      &__body-above,
      &__body-between,
      &__body-below {
        p {
          font-family: $AssistantRegular;
          span {
            font-family: $AssistantRegular !important;
          }
        }
        blockquote,
        i,
        em {
          font-family: $AssistantRegular;
        }
        strong,
        b {
          font-family: $AssistantRegular;
        }
      }
      &__author {
        font-family: $AssistantRegular;
      }
    }
    &__header-category {
      p {
        font-family: $AssistantRegular;
      }
    }
    &__footer {
      .recommended-article-category {
        p,
        span {
          font-family: $AssistantRegular;
        }
      }
    }
  }
  .custom-grid {
    &__item-inner {
      .block-image-overlay__excerpt {
        p {
          font-family: $AssistantLight;
        }
      }
    }
  }
  .page--spp__product {
    .product {
      &__header {
        .product__description-short {
          font-family: $AssistantLight;
        }
      }
      &__footer {
        .product__share {
          font-family: $AssistantLight;
        }
      }
      .shadepickerdropdown {
        a {
          font-family: $AssistantLight;
        }
      }
    }
  }
}

.site-bag {
  &__contents {
    &-edit,
    &-message,
    &-products {
      font-family: $AssistantRegular;
    }
    &-title {
      a {
        font-family: $AssistantRegular;
      }
    }
  }
}
