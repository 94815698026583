.INDhighlightLinks {
  .product__share {
    ul.product__social-links {
      li {
        color: red;
        border-bottom: 2px solid red;
        text-decoration: none;
      }
    }
  }
  .site-container {
    .mpp-custom {
      .expando-block__title {
        color: red !important;
        border-bottom: 2px solid red !important;
      }
    }
  }
  .search-filter__trigger_icon--left:before,
  .search-filter__trigger_icon--right:before {
    color: red !important;
  }
}

.INDblackwhite {
  .picker.checked {
    .picker-flag {
      background-color: white !important;
    }
  }
}

.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .cart-item {
    .cart-item__qty {
      form#cart {
        width: 28px;
        float: left;
        select {
          padding: 0;
        }
      }
    }
  }
}

.site-header {
  .site-header__tools {
    .site-bag__link-icon {
      position: relative;
      top: 0px;
      margin: 0 -23px 7px 25px;
    }
    span.site-bag__link-count {
      margin-top: -59px;
      margin-right: 0px;
    }
  }
}

.INDhighlightLinks {
  input[type='submit'],
  button[type='button'],
  div#choose-file-btn,
  div.btn,
  div.back-to-top.back-to-top-show,
  .menu--title,
  .view-all-shades {
    color: red !important;
    border-bottom: 2px solid red !important;
    text-decoration: none;
  }
  .profile-pic-upload__form-wrapper {
    div#choose-file-btn {
      span {
        color: red !important;
      }
    }
  }
  a.site-bag__link {
    height: 60px;
  }
  .icon--shopping-bag,
  .icon--search,
  .icon--bars {
    color: white;
  }
  a#site-my-mac {
    color: white !important;
  }
  .grid--mpp__item {
    .product {
      &.prod_inv_status-7 {
        a {
          border-bottom: none !important;
        }
      }
    }
  }
  .media-block__caption {
    height: 47%;
  }
  .tout-block-landscape__body {
    img {
      border-bottom: 2px solid red;
    }
  }
  .sec-nav__title-category {
    color: Red !important;
    border-bottom: 2px solid red !important;
  }
  .carousel-controls {
    transform: rotate(180deg) !important;
    height: 75px !important;
  }
  .writeareview_BVRR {
    .BVModuleButtons {
      button.BVButton {
        color: Red !important;
        border-bottom: 2px solid red !important;
      }
    }
  }
  .site-container {
    .product__reviews-spp {
      .BVPagePreviewReview {
        .BVFooter {
          .BVModuleButtons {
            .BVButton {
              color: Red;
              border-bottom: 2px solid red;
            }
          }
        }
      }
    }
  }
  #product-page-livechat-hitarea {
    .product-page-livechat-hitarea-mask {
      border-bottom: 2px solid red;
    }
  }
  .lp_mobile {
    #lpChat {
      .lp_close-icon,
      .lp_minimize-icon {
        border-bottom: 2px solid red !important;
      }
      .lp_top-text {
        color: red !important;
        border-bottom: 2px solid red !important;
      }
    }
  }
  .header-search {
    .header-search__form--content {
      border-bottom: 2px solid red;
    }
  }
}

.INDblackwhite {
  header.site-header {
    .site-banner-copy {
      .icon--remove {
        outline: 1px dashed Gold;
      }
    }
  }
  .custom-grid {
    .carousel {
      &.carousel--small-controls {
        .slick-dots {
          z-index: 10;
        }
      }
    }
  }
  .checkout__panel-content {
    .cart-item__qty {
      outline: 1px dashed Gold;
      .selectbox {
        background: url(/media/export/cms/accessibility/white-corner-triangle.png) 1px no-repeat;
        padding: 0 7% 0 7%;
        height: initial;
      }
    }
    .cart-item__total {
      line-height: 0 !important;
    }
  }
  .media-block__media,
  .block-image-overlay__image {
    -webkit-filter: invert(1);
    -moz-filter: invert(1);
    -ms-filter: invert(1);
    -o-filter: invert(1);
  }
}

.INDwhiteblack {
  header.site-header {
    .site-banner-copy {
      .icon--remove {
        outline: 1px dashed MidnightBlue;
      }
    }
  }
  .narrowed_body_copy {
    h4 {
      span {
        color: MidnightBlue !important;
      }
    }
  }
  .site-logo__image {
    background-color: black !important;
  }
  .media-block__media,
  .block-image-overlay__image {
    -moz-filter: contrast(1.5);
    -ms-filter: contrast(1.5);
    -o-filter: contrast(1.5);
    filter: contrast(1.5);
  }
}

html:not(#INDdummy) {
  body:not(#INDdummy).INDreadable > :not(#INDWrap) {
    .icon--shopping-bag,
    .icon--search,
    .icon--bars,
    .icon--remove,
    .icon--heart-o,
    .icon--instagram,
    .icon--twitter,
    .icon--pinterest,
    .icon--tumblr,
    .icon--youtube,
    .icon--facebook,
    .icon--arrow--right,
    .icon--geolocation {
      font-family: icons !important;
    }
    a.play.icon--caret-right {
      font-family: icons !important;
    }
  }
}

.INDwhiteblack,
.INDblackwhite {
  li.expanded {
    padding: 0;
  }
  .checkout__panel-content {
    .cart-item__qty {
      margin-top: 20px !important;
    }
  }
  .icon--shopping-bag {
    background: transparent !important;
  }
}

.INDmonochrome {
  .site-header {
    z-index: 1 !important;
  }
}

.INDwhiteblack.INDhighlightLinks {
  .writeareview_BVRR {
    .BVModuleButtons {
      button.BVButton {
        border-bottom: 2px solid red;
        .BVLinkSpan {
          color: red !important;
        }
      }
      button.BVButton:hover {
        background-color: lemonchiffon !important;
        .BVLinkSpan {
          color: black !important;
          background: lemonchiffon !important;
        }
      }
    }
  }
}

.INDhighlightHeaders {
  .BVMESectionHeader {
    font-weight: bold;
    border-bottom: 2px solid #222 !important;
  }
  .site-container {
    .product__reviews-spp {
      .BVPagePreviewReview {
        .BVMEPageHeader {
          border-bottom: 2px solid #222 !important;
        }
      }
    }
  }
  #BVDivCustomTagHeaderID {
    border-bottom: 2px solid #222 !important;
  }
}

#INDbtnWrap {
  #INDmenu-btn {
    svg {
      height: 35px;
    }
  }
  .enable-vto-foundation & {
    display: none;
  }
  // To hide the accessibility icon in VTO Iframe and result page
  .enable-vto & {
    display: none;
  }
}
