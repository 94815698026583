html[lang='en-ZA'],
html[lang='he-IL'],
html[lang='en-SA'],
html[lang='en-AE'],
html[lang='ar-AE'],
html[lang='ar-SA'] {
  #ot-sdk-btn {
    &.ot-sdk-show-settings {
      background-size: 15px !important;
      padding: 0 30px !important;
      height: 30px !important;
      font-weight: bold;
      cursor: pointer !important;
      margin-top: 5px !important;
      border: 1px solid $border-color !important;
      text-transform: capitalize;
      &:hover {
        color: $filter-grid-color !important;
      }
    }
  }
  .ot-sdk-container {
    .picker {
      .picker-handle {
        display: none !important;
      }
      &.checked {
        label {
          &:after {
            background: $cookie--link--active;
          }
        }
      }
    }
  }
}

html[lang='en-ZA'] {
  #onetrust-banner-sdk {
    .close-icon {
      &:hover {
        bottom: 21px !important;
      }
    }
  }
}

html[lang='he-IL'],
html[lang='ar-AE'],
html[lang='ar-SA'] {
  .ot-sdk-container {
    .picker {
      &.checked {
        label {
          &:after {
            #{$rdirection}: 16px;
          }
        }
      }
    }
  }
}

html[lang='en-ZA'],
html[lang='en-SA'],
html[lang='en-AE'] {
  .ot-sdk-container {
    .picker {
      &.checked {
        label {
          &:after {
            #{$ldirection}: 16px;
          }
        }
      }
    }
  }
}
