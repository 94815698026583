.homepage-popup {
  #cboxContent {
    background-color: $color--black;
    padding-bottom: 1em;
    #cboxClose {
      background: url('/media/export/cms/makeup_services/appointment_booking/common/close_btn_black.png')
        no-repeat center;
    }
    .icon--remove {
      &::before {
        content: '';
      }
    }
  }
  #cboxLoadedContent {
    overflow: hidden !important;
  }
}

#popover_container {
  .email-sms-signup-popup-v1 {
    background-color: $color-champagne;
    padding: 20px;
    .form--newsletter {
      &__form-items {
        padding: 0;
        .form-actions {
          padding: 0;
          border: none;
        }
        .btn {
          height: 35px;
          line-height: 1;
          margin: 0 5px;
        }
        .form-type-textfield {
          float: $ldirection;
          width: 55%;
        }
        .popup-offer-footer-msg {
          color: $color--black;
          text-align: $ldirection;
          float: $ldirection;
          margin: 0;
        }
      }
    }
  }
  .site-email-sms-signup {
    &__thank-you {
      background-color: $color-champagne;
      padding-top: 100%;
      &__content {
        color: $color--black;
      }
    }
  }
}

[dir='rtl'] {
  .tabbed-products-block {
    @include breakpoint($bp--large-up) {
      &__header {
        &.content-block {
          width: auto;
        }
        &-content {
          width: 50%;
          float: $ldirection;
          text-align: $ldirection;
        }
      }
    }
  }
  .hotspot-product {
    &__card--middle {
      @include transform(translate(50%, -50%));
    }
  }
  .virtual-events-carousel-formatter {
    &__wrapper {
      .carousel-controls {
        .slick-prev {
          #{$ldirection}: auto !important;
          #{$rdirection}: 0 !important;
        }
        .slick-next {
          #{$rdirection}: auto !important;
          #{$ldirection}: 0 !important;
        }
      }
    }
  }
  .slick-slide {
    float: left;
  }
  .search-results {
    .cr21-refresh {
      .prod_inv_status-7 {
        .sold-out {
          color: $color--black;
        }
      }
      .product--shaded {
        &.prod_inv_status-2 {
          .temp-out-of-stock {
            display: block;
          }
          .product {
            &__link-to-spp {
              display: none;
            }
          }
        }
      }
      .product {
        &__inventory-status {
          .temp-out-of-stock {
            .notify_me {
              border-radius: 100px;
              line-height: 45px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

header {
  &.virtual-events-carousel-formatter {
    &__header {
      text-align: right;
    }
  }
}

.slick-slider {
  .slick-list {
    direction: ltr;
  }
}

.basic-background-media {
  &.carddeck-hero {
    @include breakpoint($bp--large-up) {
      .carddeck-carousel-formatter {
        .card_deck-slides {
          left: 50%;
          right: auto;
        }
        &__arrows {
          &.slick-prev {
            left: 0;
            right: auto;
          }
          &.slick-next {
            right: 0;
            left: auto;
          }
        }
      }
      & .basic-background-media {
        &__content {
          &.center-aligned {
            left: 50%;
            right: auto;
          }
        }
      }
    }
  }
}

.email-signup-new-user {
  h1 {
    text-align: center;
  }
}
