@mixin pr-right-align {
  float: right;
  text-align: right;
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-content-block {
        @include breakpoint($bp--medium-down) {
          text-align: right;
        }
      }
      .pr-review {
        width: 864px;
        .pr-rd-header {
          .pr-rd-review-headline {
            @include breakpoint($bp--medium-down) {
              float: none;
            }
            @include pr-right-align();
            line-height: 18px;
          }
          .pr-rd-star-rating {
            display: inline-block;
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            @include pr-right-align();
          }
        }
        .pr-rd-footer {
          .pr-rd-bottomline {
            span {
              @include breakpoint($bp--medium-down) {
                float: none;
              }
              @include pr-right-align();
            }
          }
          .pr-rd-helpful-action {
            @include breakpoint($bp--medium-down) {
              float: none;
            }
            @include pr-right-align();
            margin: 0;
          }
          .pr-rd-helpful-text {
            margin-right: 0;
          }
          .pr-rd-flag-review-container {
            width: auto;
          }
          .pr-helpful-no,
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                margin-right: 10px;
              }
            }
          }
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'כן(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'לא(';
              }
            }
          }
        }
        .pr-rd-images {
          clear: both;
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-description {
          .pr-rd-side-content-block {
            left: -30%;
            right: auto;
          }
        }
        .pr-rd-content-block {
          &.pr-accordion {
            @include pr-right-align();
            .pr-accordion-btn {
              padding: 0;
            }
          }
        }
      }
      .pr-rd-def-list {
        dt {
          padding: 0 0 15px 15px;
        }
        dd {
          @include breakpoint($bp--medium-down) {
            padding: 0;
          }
        }
      }
      .pr-modal-content {
        .pr-flag-review-fieldset {
          text-align: right;
        }
        #pr-flag-reviews {
          input[type='radio'] {
            margin-left: 5px !important;
          }
        }
        .pr-flag-review-btn {
          @include pr-right-align();
          margin-bottom: 20px;
        }
      }
      .pr-flag-review {
        radiogroup {
          .pr-form-group {
            @include pr-right-align();
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            @include breakpoint($bp--medium-down) {
              float: none;
              text-align: center;
              padding-right: 38%;
            }
            @include pr-right-align();
            padding-left: 30%;
          }
          .pr-rd-pagination {
            text-align: center;
          }
        }
        .pr-rd-review-position {
          @include breakpoint($bp--medium-down) {
            float: none;
            text-align: center;
          }
          @include pr-right-align();
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          .pr-rd-review-header-sorts {
            .pr-rd-sort-group {
              .pr-rd-sort {
                background: url(/media/dev/icons/src/arrow--down.svg) 0% 45%/10px no-repeat;
              }
            }
          }
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            .pr-rd-sort {
              @include breakpoint($bp--medium-down) {
                background: url(/media/dev/icons/src/arrow--down.svg) 4% 45%/10px no-repeat;
              }
            }
          }
        }
      }
      .pr-flagging-radio {
        margin-right: 0;
      }
    }
    .pr-accordion {
      .pr-accordion-btn {
        @include breakpoint($bp--medium-down) {
          padding: 15px 0 0 0;
        }
      }
    }
    .pr-rd-review-product {
      text-align: right;
    }
  }
}

.write_a_review__container {
  #variant-selector {
    background: url(/media/dev/icons/src/arrow--down.svg) 4% 45%/10px no-repeat;
  }
  #pr-write {
    .pr-war {
      #pr-war-form {
        .form-group {
          select {
            background: url(/media/dev/icons/src/arrow--down.svg) 4% 45%/10px no-repeat;
          }
        }
      }
      .form-group {
        text-align: right;
        &.pr-rating-form-group {
          text-align: right;
        }
      }
      .tag-group {
        .pr-cb-tag-container {
          @include breakpoint($bp--medium-down) {
            margin-right: 0;
          }
          margin-right: 2%;
          &:nth-child(odd) {
            @include breakpoint($bp--medium-down) {
              float: none;
            }
            float: right;
            margin-right: 0%;
          }
        }
        .pr-label-control {
          text-align: right;
        }
      }
      .pr-pull-left,
      .pull-left {
        float: right !important;
      }
    }
    .thank-you-page {
      .headline,
      .col-sm-12 {
        text-align: right;
      }
      .header {
        .title,
        .subtitle,
        h5 {
          text-align: right;
        }
      }
      .pr-rating-stars {
        float: right;
      }
      #war-ty-pros {
        text-align: right;
      }
      .pr-pull-right,
      .pull-right {
        float: left !important;
      }
    }
  }
}

.product__footer {
  .pr-snippet-stars-reco-inline {
    .pr-snippet-stars-reco-stars {
      .pr-snippet-stars-container {
        &:before {
          @include breakpoint($bp--medium-down) {
            content: ' ';
          }
          content: 'דירוג כולל: ';
        }
      }
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          &:before {
            content: 'תקרא הכל ';
          }
        }
      }
    }
  }
}

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-stars-container {
          &:before {
            @include breakpoint($bp--medium-down) {
              content: ' ';
            }
            content: 'דירוג כולל: ';
          }
        }
      }
      .pr-snippet {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            &:before {
              content: 'תקרא הכל ';
            }
          }
        }
      }
    }
  }
}
